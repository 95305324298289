<template>
  <ValidationObserver @submit="enrollInCampus">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>Admin Enrollment in Campus</span>
      </template>
      <template v-slot>
        <div>
          <div>
            <div class="flex lg:gap-3 flex-col lg:flex-row">
              <UiSingleSelect
                v-model="enrollmentData.campusId"
                title="CAMPUSES"
                label="title"
                :options="campuses"
                class="flex-1"
                reduce="id"
                rules="required"
                :disabled="!!currentCampusScope"
                :search-through-api="true"
                @search="getCampus"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <template v-if="isLoading">
              <Loader />
            </template>
            <template v-else>
              <span>Assign</span>
            </template>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { Form as ValidationObserver } from 'vee-validate'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { mapActions, mapState } from 'vuex'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { removeEmptyKeysFromObject } from '@src/utils/generalUtil.js'
import UIButton from '@src/components/UiElements/UIButton.vue'

export default {
  components: {
    UiModalContainer,
    UiSingleSelect,
    Loader,
    ValidationObserver,
    UIButton,
  },
  props: {
    modal: { type: Boolean },
    currentUser: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      sectionsList: [],
      subjectsList: [],
      enrollmentData: {
        campusId: '',
        adminId: '',
      },
    }
  },
  computed: {
    ...mapState('layout', ['currentCampusScope', 'currentSectionScope', 'campuses']),
    ...mapState({ isLoading: (state) => state.layout?.isLoading }),
  },
  watch: {
    currentUser: {
      immediate: true,
      handler(value) {
        if (value) this.enrollmentData.adminId = this.currentUser?.id
      },
    },
  },
  created() {
    if (this.currentCampusScope) this.enrollmentData.campusId = this.currentCampusScope.id
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          this.enrollmentData.adminId = ''
          break
      }
    },
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },

    enrollInCampus() {
      this.$store.commit('layout/IS_LOADING', true)
      let payload = {
        role_id: 'campus_admin',
        campus_id: this.enrollmentData.campusId,
        user_id: this.enrollmentData.adminId,
      }
      removeEmptyKeysFromObject(payload)
      this.setUserRole(payload)
        .then((response) => {
          this.$emit('toggle', true)
          this.resetForm()
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
        })
    },

    resetForm() {
      this.enrollmentData.campusId = ''
      this.enrollmentData.adminId = ''
    },
    ...mapActions('staff', ['setUserRole']),
    ...mapActions('layout', ['getCampusList']),
  },
}
</script>
