import CAMPUS_ADMIN_DATA from '@/src/router/views/campus-admin/campus-admin.json'
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = (stat) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
      categories: [
        {
          text: CAMPUS_ADMIN_DATA.text,
          value: stat?.total_campus_admins || GENERAL_CONSTANTS.EMPTY_RECORD,
          tooltipDescription: CAMPUS_ADMIN_DATA.TOOLTIP_DESCRIPTION,
        },
      ],
    },
  ]

  return stats
}
export const campusAdminGraphChart = (stat) => {
  let PIE_GRAPH_VALUE = [
    stat?.total_campus_admins - stat?.login_enabled_count,
    stat?.login_enabled_count,
  ]
  const chartData = [
    GRAPH_CONSTANTS.PIE_CHART(
      CAMPUS_ADMIN_DATA.PIE_LABELS,
      PIE_GRAPH_VALUE,
      CAMPUS_ADMIN_DATA.PIE_BACKGROUND_COLOR,
      CAMPUS_ADMIN_DATA.PIE_TITLE,
    ),
  ]

  return chartData
}
