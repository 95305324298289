<template>
  <div>
    <CampusAdminFilters
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="setFilterData"
    />
    <template v-if="isLoading">
      <Loader class="mt-20 mb-40" :content="true" />
    </template>
    <div v-else-if="!isLoading && isEmpty(campusAdminList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
            :key="head.id"
          >
            <span v-i18n="dashboard">
              {{ head.dataTableName }}
            </span>
          </TH>
          <TH v-show="showColumnInDataTable(filtersData, 'Actions')" v-if="isSuperAdmin">
            Actions
          </TH>
        </TRHead>
      </THead>

      <TBody>
        <TRBody v-for="(admin, index) in campusAdminList" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
            <SingleUserDisplay :user="admin" label="full_name" :image="true" />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Phone Number', ['Phone Number'])">
            <DisplayPhoneNumber :phone="admin.phone" />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Email', ['Email'])" :lowercase="true">
            {{ admin.email || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Gender')">
            {{ admin.profile && admin.gender ? admin.gender : GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Date of Birth')">
            {{ (admin.profile && admin.profile.date_of_birth) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Blood Group')">
            {{ (admin.profile && admin.profile.blood_group) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>

          <TD v-show="showColumnInDataTable(filtersData, 'Address')">
            {{ admin?.profile?.address || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Religion')">
            {{ (admin.profile && admin.profile.religion) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Campuses')">
            <BadgeDisplay
              :options="admin.users_roles_entities"
              :nested-label="['campus', 'title']"
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Status')">
            <UiStatus
              :title="
                admin.login_activated_at
                  ? GENERAL_CONSTANTS.LOGIN_ENABLED
                  : GENERAL_CONSTANTS.LOGIN_DISABLED
              "
              :success="!!admin.login_activated_at"
            />
          </TD>
          <TD v-if="isSuperAdmin" v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :current-user="admin"
              :idx="index"
              :action-list="admin.actionsList"
              @action="campusAdminActions"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>

    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="`Pagination${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(campusAdminCounts, filteredRecordLimit)"
      @filterRecord="filterRecords"
    />
    <!-- Add & Edit Campus Admin Modal -->
    <AddCampusAdminModal
      v-if="showCampusAdminModal"
      :modal="showCampusAdminModal"
      :edit="currentAdmin"
      @toggle="toggleCampusAdminModal"
    />
    <!-- Campus Admin Activation Modal -->
    <UserEnableModal @success="fetchData" />
    <!-- Reset Password -->
    <ResetPasswordModal @success="fetchData" />
    <!-- Disable Modal -->
    <UserDisableModal @success="fetchData" />
    <!-- Deactivate Modal -->
    <UserDeactivateModal @success="fetchData" />
    <CampusAdminEnrollment
      v-if="showEnrollmentModal"
      :modal="showEnrollmentModal"
      :current-user="currentAdmin"
      @toggle="toggleTeacherEnrollmentModal"
    />
    <RoleModal
      v-if="isRoleModal"
      :modal="isRoleModal"
      user-type="Campus Admin"
      assigning-role="campus_admin"
      @toggle="toggleRoleModal"
    />
    <UIConfirmationModal
      v-if="isSetupPassword"
      heading="User Setup Password Email"
      :message="GENERAL_CONSTANTS.emailSetupMessage"
      button-text="Sent"
      :modal="isSetupPassword"
      :name="currentAdmin?.full_name"
      remaining-message=""
      @cancel="toggleUserPasswordSetupConfirmationModal"
      @confirm="sentSetupPasswordEmailHandler"
    />
  </div>
</template>

<script>
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import TableAction from '@/src/components/TableAction.vue'
import AddCampusAdminModal from '@views/admin/modals/AddCampusAdminModal.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import ResetPasswordModal from '@src/components/action-components/ResetPasswordModal.vue'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import CampusAdminFilters from '@router/views/campus-admin/CampusAdminFilters.vue'
import fileMixins from '@/src/mixins/file-mixins'
import CampusAdminEnrollment from '@views/campus-admin/modals/PermissionModal.vue'
import generalUtil from '@src/mixins/general-mixins.js'
import isEmpty from 'lodash/isEmpty'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import { mapState, mapActions, mapGetters } from 'vuex'
import { getOptionsBasedOnUserStatus } from '@src/utils/settings/tenant-user.util.js'
import UserDisableModal from '@src/components/action-components/DisableModal.vue'
import RoleModal from '@views/admin/modals/RolesModal.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import DisplayPhoneNumber from '@src/components/UiElements/PhoneDisplay.vue'
import UserDeactivateModal from '@/src/components/action-components/DeactivateModal.vue'
import UserEnableModal from '@src/components/action-components/EnableLoginModal.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { objectDeepCopy, showColumnInDataTable } from '@/src/utils/generalUtil.js'
import {
  campusAdminGraphChart,
  rightBarStats,
} from '@/src/router/views/campus-admin/campus-admin.util.js'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import CampusAdminData from '@/src/router/views/campus-admin/campus-admin.json'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import {
  TableWrapper,
  THead,
  TRHead,
  TH,
  TBody,
  TD,
  TRBody,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  components: {
    SingleUserDisplay,
    UserDisableModal,
    TableAction,
    AddCampusAdminModal,
    Loader,
    Pagination,
    UserEnableModal,
    ResetPasswordModal,
    BadgeDisplay,
    CampusAdminEnrollment,
    CampusAdminFilters,
    RoleModal,
    DisplayPhoneNumber,
    UserDeactivateModal,
    NoRecordFound,
    UIConfirmationModal,
    UiStatus,
    TableWrapper,
    THead,
    TRHead,
    TH,
    TBody,
    TD,
    TRBody,
  },
  mixins: [generalUtil, fileMixins, scrollMixin],
  data() {
    return {
      forceRender: 0,
      GENERAL_CONSTANTS,
      filtersData: {},
      isEmpty,
      TENANT_ROLES,
      isLoading: false,
      dashboard: 'dashboard',
      errorMsg: false,
      options: ['Select'],
      tableHeaders: CampusAdminData.tableHeaders,
      skipForFilterColumns: CampusAdminData.skipForFilterColumns,
      defaultSelectedColumns: CampusAdminData.defaultSelectedColumns,
      campusAdminList: null,
      currentAdmin: null,
      actionList: null,
      showCampusAdminModal: false,
      campusAdminCounts: 0,
      showPagination: false,
      filteredRecordLimit: 10,
      filterdData: null,
      showEnrollmentModal: false,
      isSetupPassword: false,
      isRoleModal: false,
    }
  },
  page: CampusAdminData.page,
  computed: {
    ...mapState({
      enableLoginModal: (state) => state.layout.enableLoginModal,
      showDeactivateModal: (state) => state.layout.showDeactivateModal,
      tabRightBar: (state) => state.layout.tabRightNav,
      showDisableModal: (state) => state.layout.showDisableModal,
      resetPassword: (state) => state.layout.resetPassword,
      showModal: (state) => state.layout.showModal,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      showUiModal: (state) => state.layout.showUiModal,
    }),
    modalValueChanges() {
      return (
        this.enableLoginModal ||
        this.showDeactivateModal ||
        this.showDisableModal ||
        this.resetPassword
      )
    },
    isSuperAdmin() {
      return this.activeRole === TENANT_ROLES.SUPER_ADMIN
    },
    ...mapState('layout', ['activeRole']),
    ...mapGetters('layout', ['isManualPasswordResetAllowed']),
  },
  watch: {
    modalValueChanges: {
      handler(value) {
        if (!value) this.currentAdmin = null
      },
    },
    showModal: {
      handler() {
        this.toggleCampusAdminModal()
      },
    },
    currentCampusScope: {
      deep: true,
      handler() {
        this.filterRecords()
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.fetchCampusAdminStats()
      },
    },

    showUiModal: {
      handler(value) {
        if (value) this.toggleRoleModal()
      },
    },
  },
  mounted() {
    this.setRightBarData()
    this.filterRecords()
  },
  methods: {
    showColumnInDataTable,
    setFilterData(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecords()
    },
    fetchData() {
      this.forceRender++
      this.filterRecords()
    },
    toggleTeacherEnrollmentModal(payload) {
      this.showEnrollmentModal = !this.showEnrollmentModal
      if (!this.showEnrollmentModal) this.currentAdmin = null

      if (payload) {
        this.filterRecords()
        this.forceRender++
      }
    },
    toggleRoleModal(payload) {
      this.isRoleModal = !this.isRoleModal
      if (!this.isRoleModal) this.$store.dispatch('layout/setShowUiModal', false)
      if (payload) {
        this.filterRecords()
        this.forceRender++
      }
    },
    setRightBarData(stats = null) {
      this.setRightbarContent({
        header: {
          title: 'CAMPUS_ADMIN',
          buttons: [
            {
              title: 'ADD_ADMIN_CAMPUS',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal' },
              disable: !this.isSuperAdmin,
            },
            {
              title: 'ADD_EXISTING_USER',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowUiModal' },
              disable: !this.isSuperAdmin,
            },
          ],
        },
        stats: rightBarStats(stats),
        chartData: campusAdminGraphChart(stats),
      })
    },
    /**
     * Perform action on campus admin
     * @param {String} action - Which action to perform
     * @param {Object} current - Current selected object
     */

    campusAdminActions(action, current) {
      this.currentAdmin = current
      this.$store.commit('layout/SET_CURRENT_SELECTED_USER', this.currentAdmin)
      switch (action) {
        case 'Edit':
          this.toggleCampusAdminModal()
          break
        case 'TV_ACTIVATE_ACCOUNT':
          this.$store.commit('layout/ENABLE_LOGIN_MODAL', true)
          break
        case 'TV_SUSPEND_ACCOUNT':
          this.$store.commit('layout/SET_SHOW_DISABLE_MODAL', true)

          break
        case 'TV_DEACTIVATE_USER':
          this.$store.commit('layout/SET_SHOW_DEACTIVATE_MODAL', true)
          break
        case 'TV_RP':
          this.$store.commit('layout/RESET_PASSWORD_MODAL', true)
          break
        case 'Add Permissions':
          this.togglePermissionModal()
          break
        case 'TV_A':
          this.toggleTeacherEnrollmentModal()
          break
        case 'TV_RE':
          this.toggleUserPasswordSetupConfirmationModal()
          break
      }
    },
    filterRecords(range) {
      this.isLoading = true
      const paginationLimit = paginationRangeHandler(range)
      let payload = {
        ...paginationLimit,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getCampusAdmin(payload)
        .then((res) => {
          this.campusAdminList = res?.records
          this.campusAdminList?.forEach((admin) => {
            admin.actionsList = this.tableActionList(admin.login_activated_at)
            admin.full_name = `${admin.first_name} ${admin.last_name}`
          })
          this.campusAdminCounts = res?.meta?.total_records
          this.showPagination = this.campusAdminCounts > GENERAL_CONSTANTS.RECORD_LIMIT
          this.fetchCampusAdminStats({ $where: this.filtersData?.$where })
        })
        .finally(() => {
          this.noRecord = isEmpty(this.campusAdminList)
          this.isLoading = false
        })
    },

    fetchCampusAdminStats(payload = { $where: {} }) {
      this.getCampusAdminStats(payload).then((res) => {
        this.setRightBarData(res)
      })
    },
    toggleUserPasswordSetupConfirmationModal() {
      this.isSetupPassword = !this.isSetupPassword
      if (!this.isSetupPassword) this.currentAdmin = null
    },

    async sentSetupPasswordEmailHandler() {
      let payload = { user_id: this.currentAdmin?.id }
      await this.resendUserSetupPassword(payload)
      this.toggleUserPasswordSetupConfirmationModal()
    },
    toggleCampusAdminModal(payload) {
      this.showCampusAdminModal = !this.showCampusAdminModal
      if (!this.showCampusAdminModal) this.currentAdmin = null
      if (payload) {
        this.filterRecords()
        this.forceRender++
      }
    },
    tableActionList(userStatus) {
      let actionList = [{ name: 'Edit' }, { name: 'TV_DEACTIVATE_USER' }]
      if (!this.currentCampusScope) actionList.push({ name: 'TV_A' })
      let options = getOptionsBasedOnUserStatus(userStatus, this.isManualPasswordResetAllowed)
      actionList = [...actionList, ...options]

      return actionList
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('campusadmin', ['getCampusAdmin', 'getCampusAdminStats']),
    ...mapActions('auth', ['resendUserSetupPassword']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
tr:nth-child(even) {
  background-color: var(--ghost-white);
}
</style>
