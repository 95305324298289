<template>
  <ValidationObserver @submit="addEditcampusAdmin">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      :disable-position-center="true"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-if="edit" v-i18n="dashboard">EDIT_ADMIN</span>
        <span v-else v-i18n="dashboard">ADD_ADMIN</span>
      </template>
      <div class="checkInModal-body">
        <div>
          <InputFieldWrapper>
            <UiInput
              v-model.trim="campusAdminData.campus_admin_info.first_name"
              type="text"
              name="First Name"
              title="First Name"
              label="First Name"
              class="flex-1"
              placeholder="Enter First Name"
              rules="required|alpha_spaces"
            />
            <UiInput
              v-model.trim="campusAdminData.campus_admin_info.last_name"
              type="text"
              name="Last Name"
              title="Last Name"
              label="Last Name"
              placeholder="Enter Last Name"
              class="flex-1"
              rules="required|alpha_spaces"
            />
          </InputFieldWrapper>

          <InputFieldWrapper>
            <UiSingleSelect
              v-model="campusAdminData.campus_admin_info.gender"
              title="Gender"
              :options="genderArray"
              class="flex-1"
            />
            <UiInput
              id="img-btn"
              ref="imgUpload"
              v-model="adminImage"
              title="Photo"
              class="flex-1"
              placeholder="Photo Upload"
              type="file"
              :preview-image="previewImage"
              :rules="imageRules"
              @preview="preview"
              @clearValue="clearValue"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <UiInput
              v-model="campusAdminData.campus_admin_info.email"
              type="email"
              name="Email"
              title="Email"
              label="Email"
              placeholder="Email"
              class="flex-1"
              :rules="isAdminActivate && !adminPhoneNumber && !edit ? 'required|email' : 'email'"
            />
            <PhoneNumber
              v-model="adminPhoneNumber"
              title="Phone Number"
              :rules="
                isAdminActivate && !campusAdminData.campus_admin_info.email && !edit
                  ? 'required|validNumber'
                  : 'validNumber'
              "
              class="flex-1"
              @update="updateAdminNumber"
            />
          </InputFieldWrapper>

          <InputFieldWrapper>
            <UiDatePicker
              v-model="campusAdminData.profile_info.date_of_birth"
              title="DOB"
              class="flex-1"
            />
            <UiInput
              v-model.trim="campusAdminData.profile_info.religion"
              type="text"
              name="Religion"
              title="Religion"
              label="Religion"
              placeholder="Religion"
              class="flex-1"
              rules="alpha_spaces"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <UiSingleSelect
              v-model="campusAdminData.profile_info.blood_group"
              title="Blood Group"
              :options="bloodGroupArray"
              class="flex-1"
            />
            <UiInput
              v-model="campusAdminData.profile_info.address"
              type="text"
              name="Address"
              title="Address"
              label="Address"
              placeholder="Address"
              class="flex-1"
            />
          </InputFieldWrapper>
          <InputFieldWrapper class="flex-col-reverse">
            <UiSingleSelect
              v-model="campusAdminData.identity_info.type"
              title="Identity Type"
              :options="IDENTITY_TYPE_OPTIONS"
              reduce="value"
              class="flex-1"
              @change="resetIdentityInfo()"
            />
            <UiSingleSelect
              v-if="!edit && !currentCampusScope"
              v-model="campusAdminData.acl_info.campus_id"
              title="CAMPUSES"
              :search-through-api="true"
              label="title"
              :options="campuses"
              class="flex-1"
              reduce="id"
              rules="required"
              @search="getCampus"
            />
          </InputFieldWrapper>
          <InputFieldWrapper>
            <div
              v-if="campusAdminData.identity_info.type"
              class="flex sm:flex-row flex-col sm:gap-6 w-full"
            >
              <UiInput
                v-model="campusAdminData.identity_info.value"
                type="text"
                name="Identity Number"
                title="Identity Number"
                label="Identity Number"
                placeholder="Enter Identity No"
                class="flex-1"
                rules="required|numeric"
              />
              <UiInput
                v-model="campusAdminData.identity_info.country"
                type="text"
                name="Country"
                title="Country"
                label="Country"
                placeholder="Enter Country"
                class="flex-1"
                rules="required|alpha"
              />
            </div>
          </InputFieldWrapper>
          <InputFieldWrapper class="justify-end">
            <ToggleButton
              v-if="!edit"
              v-model="isAdminActivate"
              heading="Enable Login"
              class="w-full sm:w-[48%]"
              :is-status-hide="true"
            />
          </InputFieldWrapper>
        </div>
      </div>
      <template v-slot:footer>
        <div class="flex gap-3 rtl:gap-x-4">
          <UIButton :disabled="isLoading" @click="handleClick('close')">Cancel</UIButton>
          <UIButton button="primary" :disabled="isLoading">
            <div v-if="isLoading">
              <Loader></Loader>
            </div>
            <div v-else>
              {{ btnText }}
            </div>
          </UIButton>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
/* COMPONENTS */
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import PhoneNumber from '@components/UiElements/PhoneNumber.vue'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import ToggleButton from '@src/components/BaseComponent/toggleButton.vue'
import { IDENTITY_TYPE_OPTIONS } from '@src/constants/general-constants.js'
import RULE_CONSTANTS from '@src/constants/rule.constants.js'
/* MIXINS */
import generalMixin from '@src/mixins/general-mixins.js'
/* STATE */
import { mapActions, mapState } from 'vuex'
import { setObjectToEmptyFromNull, removeNestedObjectEmptyKeys } from '@src/utils/generalUtil.js'
import { transformCampusAdminData } from '@src/utils/settings/tenant-user.util.js'
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'
import { Form as ValidationObserver } from 'vee-validate'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

/* EXPORT AND RENDER */
export default {
  components: {
    UiModalContainer,
    UiInput,
    UiDatePicker,
    UiSingleSelect,
    Loader,
    ValidationObserver,
    PhoneNumber,
    ToggleButton,
    UIButton,
    InputFieldWrapper,
  },
  mixins: [generalMixin],
  props: {
    modal: { type: Boolean },
    edit: { type: Object, default: () => {} },
  },
  emits: ['toggle'],
  data() {
    return {
      IDENTITY_TYPE_OPTIONS,
      adminPhoneNumber: '',
      options: ['Select'],
      previewImage: null,
      loadClassList: [],
      adminImage: null,
      dashboard: 'dashboard',
      genderArray: [this.$t(`dashboard.male`), this.$t(`dashboard.female`)],
      bloodGroupArray: ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'],
      steps: [
        { stat: 1, info: 'Personal Info', active: true, markStatus: false },
        { stat: 2, info: 'Enrollment Info', active: false, markStatus: false },
      ],
      campusAdminData: {
        campus_admin_info: {
          name: '',
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          gender: '',
          login_activated_at: '',
        },
        profile_info: {
          blood_group: '',
          date_of_birth: '',
          religion: '',
          nationality: '',
          height: '',
          weight: '',
          image: '',
          address: '',
        },
        identity_info: {
          type: '',
          value: '',
          country: '',
        },

        acl_info: {
          campus_id: '',
        },
      },
      isAdminActivate: true,
    }
  },
  computed: {
    ...mapState('layout', ['campuses', 'isLoading', 'currentCampusScope']),
    imageRules() {
      return `${RULE_CONSTANTS.SIZE}`
    },

    btnText() {
      if (this.edit) {
        return 'Update'
      } else {
        return 'Save'
      }
    },
  },

  created() {
    if (this.edit) this.setAdminData()
    if (this.currentCampusScope && this.campusAdminData.acl_info) {
      this.campusAdminData.acl_info.campus_id = this.currentCampusScope.id
    }
  },
  methods: {
    resetIdentityInfo() {
      this.campusAdminData.identity_info.value = ''
      this.campusAdminData.identity_info.country = ''
    },
    updateAdminNumber(payload) {
      this.campusAdminData.campus_admin_info.phone = payload.formattedNumber
    },

    /**
     * Set selecte image to preview
     * @param {Object} image- selected image
     */
    preview(image) {
      this.previewImage = image
    },
    clearValue(image) {
      this.previewImage = null
      this.adminImage = image
    },

    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          this.previewImage = null
          break
        case 'cancel':
          break
        case 'confirm':
          break
      }
    },
    addEditcampusAdmin() {
      if (this.edit) this.editExistingAdmin()
      else this.addNewAdmin()
    },

    /**
     * Edit existing admin
     * Append admin data and its profile picture in form data
     * Send put request with form data
     */

    editExistingAdmin() {
      this.$store.commit('layout/IS_LOADING', true)
      this.campusAdminData.campus_admin_info.login_activated_at = this.isAdminActivate
        ? currentUTCDateTimeWithZone()
        : ''

      const form = new FormData()
      if (this.adminImage) form.append('profile_image', this.adminImage)
      if (this.adminPhoneNumber) form.append('phone', this.adminPhoneNumber)
      form.append('first_name', this.campusAdminData.campus_admin_info.first_name)
      form.append('last_name', this.campusAdminData.campus_admin_info.last_name)
      form.append('gender', this.campusAdminData.campus_admin_info.gender)
      form.append('email', this.campusAdminData.campus_admin_info.email)
      form.append('profession', this.campusAdminData.campus_admin_info.profession)
      form.append('address', this.campusAdminData.profile_info.address)
      form.append('login_activated_at', this.campusAdminData.campus_admin_info.login_activated_at)
      form.append('blood_group', this.campusAdminData.profile_info.blood_group)
      form.append('date_of_birth', this.campusAdminData.profile_info.date_of_birth)
      form.append('religion', this.campusAdminData.profile_info.religion)
      form.append('nationality', this.campusAdminData.profile_info.nationality)
      form.append('height', this.campusAdminData.profile_info.height)
      form.append('weight', this.campusAdminData.profile_info.weight)
      form.append('type', this.campusAdminData.identity_info.type)
      form.append('value', this.campusAdminData.identity_info.value)
      form.append('country', this.campusAdminData.identity_info.country)

      const data = {
        admin: form,
        id: this.edit?.id,
      }
      this.editCampusAdmin(data)
        .then((res) => {
          this.$emit('toggle', true)
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
          this.previewImage = null
        })
    },
    getCampus(query = '') {
      let payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'title', query),
        },
      }
      this.getCampusList(payload)
    },

    /**
     * Add new admin
     * Append admin data and its profile picture in form data
     * Send post request with form data
     */
    addNewAdmin() {
      this.$store.commit('layout/IS_LOADING', true)
      const data = new FormData()
      if (this.isAdminActivate)
        this.campusAdminData.campus_admin_info.login_activated_at = currentUTCDateTimeWithZone()
      if (this.adminImage) data.append('profile_image', this.adminImage)
      if (this.adminPhoneNumber) data.append('phone', this.adminPhoneNumber)

      data.append('first_name', this.campusAdminData.campus_admin_info.first_name)
      data.append('last_name', this.campusAdminData.campus_admin_info.last_name)
      data.append('gender', this.campusAdminData.campus_admin_info.gender)
      data.append('email', this.campusAdminData.campus_admin_info.email)
      data.append('profession', this.campusAdminData.campus_admin_info.profession)
      data.append('address', this.campusAdminData.profile_info.address)
      data.append('login_activated_at', this.campusAdminData.campus_admin_info.login_activated_at)
      data.append('blood_group', this.campusAdminData.profile_info.blood_group)
      data.append('date_of_birth', this.campusAdminData.profile_info.date_of_birth)
      data.append('religion', this.campusAdminData.profile_info.religion)
      data.append('nationality', this.campusAdminData.profile_info.nationality)
      data.append('height', this.campusAdminData.profile_info.height)
      data.append('weight', this.campusAdminData.profile_info.weight)
      data.append('type', this.campusAdminData.identity_info.type)
      data.append('value', this.campusAdminData.identity_info.value)
      data.append('country', this.campusAdminData.identity_info.country)

      if (!this.currentCampusScope)
        data.append('campus_id', this.campusAdminData.acl_info.campus_id)

      this.createCampusAdmin(data)
        .then(() => {
          this.$emit('toggle', true)
        })
        .finally(() => {
          this.$store.commit('layout/IS_LOADING', false)
          this.previewImage = null
        })
    },

    setAdminData() {
      this.campusAdminData = transformCampusAdminData(this.edit)
      this.isAdminActivate = !!this.edit?.login_activated_at
      this.previewImage = {}
      this.previewImage.src = this.edit?.image || (this.previewImage = null)
      this.adminPhoneNumber = this.edit?.phone
      this.steps.length = 1
    },
    /**
     * STORE METHODS
     */
    ...mapActions('layout', ['getCampusList']),
    ...mapActions('campusadmin', ['createCampusAdmin', 'editCampusAdmin']),
  },
}
</script>

<style lang="scss" scoped>
.widthItems {
  min-width: 30%;
}
.minHieght80 {
  min-height: 80vh;
}
.active {
  .step-counter {
    color: var(--white);
    background-color: var(--primary-green);
    border-color: var(--primary-green);
    box-shadow: 0 7px 14px var(--primary-green-dark);
  }
  .step-info {
    color: var(--text-color);
  }
}
</style>
